<template>
<div>
    <!-- <button v-if="!show" class="badge btn-prime btn-xs" @click="showtable=!showtable">Active Complaint</button> -->
    <h5 class="text-center">Job Scheduled</h5>
    <table class="table table-bordered table-font" style="margin-bottom:0;color:#279d00;font-size:13px!important;">
        <thead>
            <tr>
                <th>S.No</th>
                <!-- <th>Request</th> -->
                <th>Conversation</th>
                <!-- <th>Followed By</th> -->
                <th>Entered BY</th>
                <th>Schedule Date</th>
                <!-- <th>Lead By</th> -->
                <!-- <th>Customer Complaint</th> -->
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item,index) in list" v-bind:key="item.id">
                <td>{{ index+1 }}</td>
                <!-- <td>{{ requestmethod(item) }}</td> -->
                <td>{{ item.detail }}</td>
                <!-- <td><span>{{item.followby!=null?item.followby.name:''}}</span></td> -->

                <td>{{ item.enteredby?.generatedid }}
                    <br/>{{ item.enteredby!=null?item.enteredby.name:''}}<br>
                    {{ moment(item.created_at).format("DD-MM-YYYY") }}<br/>
                {{ moment(item.created_at).format("hh:mm:ss A") }}</td>
                <td>{{ moment(item.scheduledate).format("DD-MM-YYYY")}}</td>
                <!-- <td>{{ leadbymethod(item)}}</td> -->
                <!-- <td>{{ item.leadremark}}</td> -->
            </tr>
        </tbody>
    </table>
    <div class="col-md-12">
        <div class="panel panel-cascade">
            <div class="panel-body" style="background-color:#d0e5cc">
                <div class="form-horizontal cascde-forms">
                    <!-- <div class="form-group row" v-if="!show">
                        <label class="col-lg-4 col-md-3 control-label">Request<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select class="form-control form-cascade-control input-small" v-model="requesttype" >
                                <option value="2">COMPLAINT</option>
                            </select>
                            <font color="red"><span class="field-validation-valid" data-valmsg-for="REQUEST" data-valmsg-replace="true"></span></font>
                        </div>
                    </div> -->
                    <div class="form-group row" v-if="!show">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                        Remark<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <textarea class="form-control form-cascade-control input-small" v-model="detail" cols="20" id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                        </div>
                    </div>
                    <!-- <div class="form-group row" v-if="loggedinuser.role>=2 && !show">
                        <label class="col-lg-4 col-md-3 control-label">Followed By<span style="color:red">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select v-model="followedid" class="form-control form-cascade-control input-small">
                                <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                            </select>             
                            <font color="red"><span class="field-validation-valid" data-valmsg-for="FOLLOWEDBYID" data-valmsg-replace="true"></span></font>
                        </div>
                    </div>
                    <div class="form-group flex-row" v-if="loggedinuser.role<2 && !show">
                        <label class="col-lg-4 col-md-3 control-label">Followed By<span style="color:red">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select v-model="followedid" class="form-control form-cascade-control input-small">
                                <option v-for="filter in employees.filter(property => property.id ==loggedinuser.id )" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
                            </select>             
                            <font color="red"><span class="field-validation-valid" data-valmsg-for="FOLLOWEDBYID" data-valmsg-replace="true"></span></font>
                        </div>
                    </div> -->
                    <div class="form-group row" v-if="show">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                        Conversation<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <textarea class="form-control form-cascade-control input-small" v-model="detail" cols="20" id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                        </div>
                        <!-- <div class="col-lg-2">
                            <button class="badge btn-prime btn-xs" @click="shownewlead=!shownewlead">New Lead</button>
                        </div> -->
                    </div>
                        <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">Schedule Date<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <!-- <input class="form-control form-cascade-control input-small" :disabled="showdate" v-model="scheduledate"  type="date" > -->
                            <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="scheduledate">
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                        <button type="button"
                                            class="date-picker"
                                            @click="togglePopover()">
                                            <i class='bx bx-calendar'></i>
                                        </button>
                                        <input :value="inputValue" @click="togglePopover()"
                                            class="form-control form-cascade-control input-small"
                                            placeholder="DD/MM/YYYY" readonly />
                                    </div>
                                </template>
                            </vc-date-picker>
                        </div>
                        <!-- <div class="col-lg-2" >
                            <button class="badge btn-prime btn-xs"  @click="showdate=!showdate">Schedule</button>
                        </div> -->
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3"></div>
                    <div class="col-md-5 flex-around-row">
                        <button type="button" class="btn btn-primary" style="margin-top:0" @click="submit()">Schedule</button>
                        <button v-if="item.status==0 && show" type="button" class="btn btn-success" style="margin-top:0" @click="resolved()">Resolved</button>
                        <button v-if="item.status==1 && show" type="button" class="btn btn-danger" style="margin-top:0" @click="resolved()">Make Pending</button>
                        <button v-if="show" type="button" class="btn btn-danger" @click="disposeoff()">Dispose</button>
                    </div>
                </div>
                <p style="color:#2196f3;font-size:13px">* If You want to Dispose Lead, You don't need to fill Schedule Date</p>
            </div>
            
        </div>
    </div>
</div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Constants from '../../components/utilities/Constants.vue';
export default {
    mixins:[Constants],
    props:['item','show'],
    data(){
        return {
            id:0,subject:'',detail:'',scheduledate:'',followedid:0,
            list:[],
            moment:moment,
            requesttype:2,
            // shownewlead:false,
            newleadremark:'',
            showtable:false,
            showdate:true,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
        }
    },
    validations:{
        scheduledate:{
            required
        }
    },
    mounted(){
        this.refresh();
    },
    computed:{
        ...mapGetters([
            'loggedinuser','employees'
        ]),
    },
    methods:{
        refresh(){
            if(this.item!=null){
                this.requesttype=this.item.requesttype
                this.newleadremark=this.item.remark
                this.followedid=this.item.followedid
            }
            if(this.show==false){
                this.$store.dispatch('fetchemployees')
            }
            let param = {recruitid:this.item.id}
            this.$store.commit('assignloadingstatus',1)
            this.$http.post('api/leads/customerconversation/fetch',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        submit(){
            if(this.$v.$invalid){
                this.$notify({
                    text:'Schedule Date is Compulsory',
                    type:'error',
                    duration:'50000',
                    speed:'300',
                    closeOnClick:false
                })
            }else{
                let param = {recruitid:this.item.id,detail:this.detail,scheduledate:this.scheduledate,
                        requesttype:5,leadremark:this.newleadremark,
                        followedid:this.followedid}
                    this.$http.post('api/leads/customerconversation',param)
                    .then((response) => this.processDetail(response.data))
                    .catch((err) => {
                        console.log('', err)
                    });
            }
        },
        processDetailResponse(data){
            this.$store.commit('assignloadingstatus',0)
            this.list=data
        },
        processDetail(){
            this.$parent.visitcomments = false
            if(this.show==false){
                let param = {isdispose:0,id:this.item.id}
                this.$store.commit('assignloadingstatus',1)
                this.$http.post('api/leads/conversation/enabledisposeoff',param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }
            
            this.$parent.refresh();
        },
        processLeadResponse(){
            this.$store.commit('assignloadingstatus',0)
            this.$parent.refresh();
        },
        requestmethod(item){
            let type="";
            switch (item.requesttype) {
                case 1:
                    type= "QUERY";
                    break;
                case 2:
                    type= "Complaint";
                    break;
                case 3:
                    type= "Feedback";
                    break;
                case 4:
                    type= "ORDER";
                    break;
                default:
                    break;
            }
            return type;
        },
        
        disposeoff(){
            if(this.loginusercheck(171)){
                if(confirm("Do You Really Want To Dispose")==true){
                    let param = {leadid:this.item.id,detail:this.detail,scheduledate:this.scheduledate,
                        requesttype:this.requesttype,leadremark:this.newleadremark,
                            followedid:this.followedid}
                    this.$http.post('api/leads/conversation/dispose',param)
                    .then((response) => this.processDetailDispose(response.data))
                    .catch((err) => {
                        console.log('', err)
                    });
                }
            }else{
                alert('You dont have right to Dispose')
            }
        },
        processDetailDispose(){
            this.$parent.visitcomments = false
            this.$parent.refresh();
        },
        resolved(){
            // if(this.loginusercheck(172)){
                if(confirm("IS Complaint Resolved")==true){
                    let param={id:this.item.id,column:"status",value:this.item.status==0?1:0}
                    this.$http.post('api/leads/updatefield',param)
                        .then((response) =>this.processUpdateResponse(response.data))
                        .catch((err) => {
                            console.log('', err)
                        });
                }
            // }else{
            //     alert("You dont have right to resolved")
            // }
        },
        processUpdateResponse(){
            this.$parent.visitcomments = false
            this.$parent.refresh();
        }
    }
}
</script>